<template>
    <div class="one_code">
        <!-- <p v-if="isWXRet">is weixin </p> -->
        <wx-open-launch-weapp v-if="showPushRet" id="launch-btn" appid="wxd20f2b63ea9ce6b7" username="gh_2e2fea57f269" :path="tinyPath">
            <script type="text/wxtag-template">
                <style>.btn { padding: 40px }</style>
                    <button class="btn">打开小程序</button>
                        </script>
        </wx-open-launch-weapp>
    </div>
</template>
<script>
import Vconsole from 'vconsole'

let vConsole = new Vconsole()
import {
    requestQueryDataTGPMethod,
} from "../utils/appManager";
import { wxconfig } from "./wxApi.js"
export default {
    name: "onecodepush",
    components: {

    },
    data() {
        return {
            stockCode: '',
            stockName: '',
            isWXRet: false,
            jssdkTicket: '',
            jssdkTicket2: '',
            showPushRet:false,
            tinyPath:"pages/onlyStocks/onlyStocks?stockCode="
        };
    },
    mounted() {

        this.stockCode = this.$route.query.stockcode;
        this.stockName = this.$route.query.stockname;
        this.tinyPath = "pages/onlyStocks/onlyStocks?stockCode=" + this.stockCode;
        this.isWXRet = Boolean(navigator.userAgent.match(/MicroMessenger/ig));
        var baseStr = 'https://action:9999?url=127.0.0.1&&haveNav=0&&fileName=onlyStocks&&title=' + this.stockName + '&&hasBack=0&&firstLoad={"stockCode":"' + this.stockCode + '"}';
        if (this.isWXRet) {
            this.loadWX();
        } else {
            var baseU = navigator.userAgent;
            var isIos = !!baseU.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isIos) {
                window.location.href = baseStr;
            } else {
                setTimeout(() => {
                    window.AlipayJSBridge &&
                        AlipayJSBridge.call(
                            "CCThirdApi",
                            {
                                actionType: "pushManager",
                                url: baseStr,
                                source: window.location.href,
                            },
                            function (data) { }
                        );
                }, 300);
            }
        }
    },
    methods: {
        loadWX() {
            document.addEventListener("WeixinOpenTagsError", function (t) {
                console.log("cccc");
                alert(t.detail.errMsg);
            });
            this.getjssdkTicket();
            var btn = document.getElementById('launch-btn');
            btn.addEventListener('launch', function (e) {
                console.log('success');
                console.log(e);
                console.log(e.detail);
            });
            btn.addEventListener('error', function (e) {
                console.log('fail', e.detail);
            });
        },
        getjssdkTicket() {
            requestQueryDataTGPMethod(
                "v4/wechat/getJssdk",
                { jsApiList: ['launchApplication', 'getInstallState'], url: window.location.href, openTagList: ['wx-open-launch-weapp'] },
                "getjssdkTicketSuccess",
                "0",
                "post"
            );
        },
    },
    created() {
        window["getjssdkTicketSuccess"] = (data) => {
            console.log(data);
            this.jssdkTicket = data.data.signature;
            wxconfig(this.jssdkTicket, data.data.timestamp, data.data.url, data.data.nonceStr, data.data.appId);
            setTimeout(() => {
                this.showPushRet=true;
            }, 1000);
        }
    }
}
</script>
<style lang="scss">
#launch-btn {
    margin-left: 50%;
    margin-top: 40px;
    transform: translateX(-50%);
}
.one_code {
    background-color: #ffffff;
    height: 100vh;
}
</style>
